<template>
 <div class="overflow-hidden bg-white py-24 sm:pt-28 sm:pb-32">
  <div class="mx-auto max-w-7xl">
   <div
    class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
   >
    <div class="lg:pr-8 lg:pt-4">
     <div class="lg:max-w-lg">
      <h2 class="text-base font-semibold leading-7 encom_primary_text">
       {{ $t("primaryTextNoAuthPage") }}
      </h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       {{ $t("secondPrimaryTextNoAuthPage") }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">{{ $t("paragraphNoAuthPage") }}.</p>
      <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
       <div class="relative pl-9">
        <dt class="inline font-semibold text-gray-900">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute left-1 top-1 h-5 w-5 encom_primary_text"
         >
          <path
           fill-rule="evenodd"
           d="M13 3v1.27a.75.75 0 001.5 0V3h2.25A2.25 2.25 0 0119 5.25v2.628a.75.75 0 01-.5.707 1.5 1.5 0 000 2.83c.3.106.5.39.5.707v2.628A2.25 2.25 0 0116.75 17H14.5v-1.27a.75.75 0 00-1.5 0V17H3.25A2.25 2.25 0 011 14.75v-2.628c0-.318.2-.601.5-.707a1.5 1.5 0 000-2.83.75.75 0 01-.5-.707V5.25A2.25 2.25 0 013.25 3H13zm1.5 4.396a.75.75 0 00-1.5 0v1.042a.75.75 0 001.5 0V7.396zm0 4.167a.75.75 0 00-1.5 0v1.041a.75.75 0 001.5 0v-1.041zM6 10.75a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75zm0 2.5a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75z"
           clip-rule="evenodd"
          />
         </svg>

         {{ $t("ticketingService") }}.
        </dt>
        <dd class="inline">{{ $t("ticketingServiceDetail") }}.</dd>
       </div>
       <div class="relative pl-9">
        <dt class="inline font-semibold text-gray-900">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute left-1 top-1 h-5 w-5 encom_primary_text"
         >
          <path
           d="M12 9a1 1 0 01-1-1V3c0-.553.45-1.008.997-.93a7.004 7.004 0 015.933 5.933c.078.547-.378.997-.93.997h-5z"
          />
          <path
           d="M8.003 4.07C8.55 3.992 9 4.447 9 5v5a1 1 0 001 1h5c.552 0 1.008.45.93.997A7.001 7.001 0 012 11a7.002 7.002 0 016.003-6.93z"
          />
         </svg>

         {{ $t("advancedStatistics") }}.
        </dt>
        <dd class="inline">{{ $t("advancedStatisticsDetail") }}.</dd>
       </div>
       <div class="relative pl-9">
        <dt class="inline font-semibold text-gray-900">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="absolute left-1 top-1 h-5 w-5 encom_primary_text"
         >
          <path
           d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z"
          />
         </svg>

         {{ $t("SmartRouting") }}.
        </dt>
        <dd class="inline">{{ $t("SmartRoutingDetail") }}.</dd>
       </div>
      </dl>
     </div>
    </div>
    <img
     src="../assets/get-started.png"
     alt="Product screenshot"
     class="max-w-7xl rounded-xl shadow-xl ring-1 ring-gray-200 md:-ml-4 lg:-ml-0"
    />
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";

export default {
 props: [""],
 components: {},
 data() {
  return {};
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
